

.Container {
    max-height: 100vh;
}
.Circle {
    position: fixed;
    right: -20vw;
    top: 50vh;
    width: 50vw;
    height: 50vw;
    border-radius: 1500px;
    background: #F0F3F8;
    z-index: -1;
    overflow: hidden;
}
.Title {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 5px;
}

.Description {
    color: rgba(0, 0, 0, 0.62);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 100%; /* 32px */
    letter-spacing: 2px;
}
.ContentContainer{
    width: 80%;
    padding: 15vh 2rem;

}
.LoginButton {
    display: none;
}


@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px) {



    .Title {
        font-size: 2rem;
        text-align: center;
    }
    .Description {
        font-size: 1rem;
        text-align: center;

    }
    .ContentContainer {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding-top: 1rem;
    }

    .LoginButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        border: 1px solid black;

    }

    .LoginButton img {
        width: 1.2rem;
    }




}