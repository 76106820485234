.Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.LeftSide {


}
.RightSide {
    width: 100%;
    min-height: 100vh;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px){
    /*Mobile*/

    .Container {
        flex-flow: column;
    }
    .LeftSide {

    }
    .RightSide {
        width: 100%;
    }

}
