.Container {
    border-bottom: 3px solid #F0F3F8;
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;


}
.LeftSide input {
    display: none;
    width: 25vw;
    border-radius: 10px;
    background-color: #F0F3F8;
    height: 4vh;
    border: none;
    padding-left: 1rem;
    font-size: 1.2rem;
}
.LoginButton {
    color: #000;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-decoration: none;
    border-left: 3px solid #F0F3F8;
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-left-color 300ms linear;
}

.LoginButton:hover {
    border-left: 3px solid #e9f1ff;
    transition: border-left-color 300ms linear;
}

.LoginButton img {
    width: 1.5rem;
    margin-left: 0.5rem;
}


@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px){
/*Mobile*/

    .Container {
        display: none;
    }


}
