.Container {
    min-width: 100%;
    height: 100vh;
    overflow: scroll;
}
.InfoSection {
    padding-left: 3rem;
    padding-bottom: 10vh;

}

.Title {
    padding-top: 5vh;
    color: #000;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
}
.TitleButton img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    padding: 0.8rem;
    border-radius: 100%;
    transition: background-color 0.5s linear, border-color 0.5s linear;
    border: 2px solid #F0F3F8;

}


.TitleButton {
    background-color: transparent;
    margin-right: 2rem;
    border: none;

}
.TitleButton img:hover {
    background-color: #F0F3F8;
    border: 2px solid black;

    transition: background-color 0.5s linear, border-color 0.5s linear;
}

.Date {
    color: #070707;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 2px;
}

.Description {
    width: 75%;
    color: rgba(0, 0, 0, 0.70);
    font-size: 1.3rem;
    font-weight: 400;

}

.GallerySection{
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    justify-content: center;
}
.GalleryImg {
    width: 19%;
    margin: 0.2rem;
}

.AdminDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 30%;
    margin-top: 2rem;

}


.AdminButtons {
    border-radius: 10px;
    background: #F0F3F8;
    border: none;
    font-size: 1.5rem;
    padding: 0.1rem 1.2rem;
    transition: background-color 300ms linear;

}
.AdminButtons img {
    width: 1.2rem;
    margin-left: 0.5rem;
}

.AdminButtons:hover {
    transition: background-color 300ms linear;
    background-color: #e9f1ff ;
}

.TitleEditDiv {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}
.TitleEdit {
    border-radius: 10px;
    border: 3px solid #dae0ec;
    background: #FFF;
    width: 20vw;
    height: 7vh;
    padding-left: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
}

.DateEdit {
    border-radius: 10px;
    border: 3px solid #DAE0EC;
    background: #FFF;
    width: 15vw;
    height: 7vh;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.DescriptionEdit {
    border-radius: 10px;
    border: 3px solid #DAE0EC;
    background: #FFF;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    width: 50vw;
    max-height: 40vh;
    min-height: 40vh;

}

.thumbnailInput {
    border-radius: 10px;
    background: #F0F3F8;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    width: 25%;
    padding: 0.5rem 2rem;
    margin-left: 2rem;
}
.isPending{
    font-size: 1.5rem;
    font-weight: 400;
    margin: 3rem;
}



@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px) {
    /*Mobile*/


    .Container {
        min-width: 100%;
        height: 100vh;
        overflow: scroll;
    }

    .Container {
        max-width: 100vw;
        height: fit-content;
        overflow: auto;

    }
    .InfoSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        padding: 1rem 0 0;


    }
    .Title {
        padding: 0;
        font-size: 1.5rem;
    }

    .TitleButton{
        margin-right: 0.1rem;
        padding-left: 0.1rem;
    }

    .TitleButton img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        padding: 0.7rem;
        border-radius: 100%;
        transition: background-color 0.5s linear, border-color 0.5s linear;
        border: 2px solid #F0F3F8;

    }
    .Description {
        width: 90%;
        text-align: center;

    }
}