.Container {
    display: flex;
    flex-flow: column;
    width: fit-content;
    margin-top: 5vh;
    margin-left: 5rem;

}

.ButtonsDiv {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.ButtonsDiv button {
    border-radius: 10px;
    background: #F0F3F8;
    border: none;
    font-size: 1.3rem;
    padding: 0.3rem 1.2rem;
    margin-bottom: 10vh;
    transition: background-color 300ms linear;

}
.ButtonsDiv button img {
    width: 1.2rem;
    margin-left: 0.5rem;
}

.ButtonsDiv button:hover {
    transition: background-color 300ms linear;
    background-color: #e9f1ff ;
}

.thumbnailInput {
    border-radius: 10px;
    background: #F0F3F8;
    border: none;
    font-size: 0.9rem;
    font-weight: 700;
    display: flex;
    margin-bottom: 1rem;
    width: 35%;
    padding: 0.5rem 2rem;
}

.Container textarea {
    width: 50vw;
    max-height: 40vh;
    min-height: 40vh;
}

.InputDesign {
    border-radius: 10px;
    border: 3px solid #DAE0EC;
    background: #FFF;
    width: 15vw;
    height: 6vh;
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
}

.DateDesign {
    padding-right: 1rem;
    color: rgba(0, 0, 0, 0.54);
}

.isPending {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 3rem;
}