.Container {
    display: flex;
    min-width: 100%;
    min-height: fit-content;
    padding: 0.5rem 1rem 0.5rem 5rem ;

}
.LeftSide{
    width: 20%;
    min-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RightSide{
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-left: 2rem;
    border-bottom: 4px solid #F0F3F8;
    min-width: 80%;
    padding-bottom: 2rem;
}
.ThumbnailImg {
    width: 100%;
    border-radius: 20px;
}
.Title {
    color: #000;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 0.1;
    padding-top: 2rem;
}

.Date {
    color: #070707;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 2px;
}

.Description {
    min-width: 85%;
    color: rgba(0, 0, 0, 0.70);
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 100%;
}

.SeeMoreButton {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    width: fit-content;
    border-bottom: 3px solid transparent;
    transition: border-bottom-color 0.5s linear;
}

.SeeMoreButton:hover {
    border-bottom: 3px solid black;
    transition: border-bottom-color 0.5s linear;
}


.recentLeftSide{
    min-width: 27%;

}

.recentRightSide{
    min-width: 70%;
    border: none;
}
.recentContainer {

}

.recentDescription {
    width: 80%;
}

.OverLay {
    display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px){
    /*Mobile*/

    .Container {
        flex-flow: column;
        padding: 1rem 0;
        margin: 0;
    }

    .ThumbnailImg {
        border: 3px solid black;
        padding-top: 0.5rem;
    }
    .LeftSide{
        width: 90%;
        min-width: 90%;
        margin: 0 auto;
        position: relative;
    }

    .RightSide {
        display: none;
    }

    .OverLay {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        border-radius: 20px;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(27,38,37,1) 91%);
    }
    .Title {
        color: white;
        font-size: 1.2rem;
        padding-left: 1rem;
        font-weight: 500;
        display: none;

    }
    .Date {
        color: white;
        font-size: 1rem;
        padding-left: 1rem;
        font-weight: lighter;
    }

}