.Container {
    height: 100vh;
    width: 17vw;
    background-color: #F0F3F8;
    padding-top: 1.5rem;
}
.LogoDiv {
    display: flex;
    align-items: center;
    justify-content: center;

}
.LogoDiv img {
    width: 12vw;

}

.NavElementsDiv{
    margin-top: 2.5rem;
    height: 65vh;
    display: flex;
    flex-flow: column;
}
.NavElement {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.50);
    background-color: transparent;
    border: none;
    padding: 0.5rem 0;
    min-width: 55%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-decoration: none;

}

.NavElement img {
    width: 1.4rem;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
}


.ActiveNavElement {
    font-weight: 600;
}

.SocialMediaDiv {
    width: 100%;

}

.SocialMediaDiv p {
    color: #000;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-align: center;

}

.socialMediaList {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
}

.socialMediaImg {
    width: 1.3rem;
}




@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px){
    /*Mobile*/
    .Container {
        width: 100vw;
        height: 9vh;
        background: white;
        padding: 0;
        display: flex;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        justify-content: center;
        align-items: center;
    }
    .LogoDiv {
        display: none;
    }
    .SocialMediaDiv {
        display: none;
    }

    .NavElementsDiv {
        width: 100%;
        margin: 0;
        padding: 0;
        height: inherit;
        flex-flow: row;

    }
    .NavElement {
        padding: 0;
        margin: 0 auto;
        min-width: fit-content;
        display: flex;
        align-items: center;
        text-decoration: none;

    }

    .elementNames{
        display: none;
    }

    .NavElement img {
        margin: 0;
        width: 1.6rem;
    }


}

