.Container {
    max-height: 100vh;
    overflow: scroll;
    overflow-x: hidden;

}
.TitleDiv {
    margin-top: 10vh;
    display: flex;
    align-items: center;
    padding-left:5rem;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.Title {
    color: #000;
    font-size: 2rem;
    font-weight: 400;
    line-height: 0;
}
.Line {
    width: 80%;
    height: 2px;
    background: #F0F3F8;
}
.RecentEventTitle {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 2px;
    padding-left: 5rem;
    padding-top: 1rem;

}



.AddEventButton {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2px;
    width: 17vw;
    height: 17vw;
    border-radius: 30px;
    border: 3px solid #DAE0EC;
    background: rgba(0, 0, 0, 0.00);
    margin-left: 5rem;
    transition: background-color 300ms linear, border-color 300ms linear;
}

.AddEventButton img {
    background-color: #DAE0EC;
    border-radius: 100%;
    width: 2.5rem;
    transition: background-color 300ms linear;
}

.AddEventButton:hover{
    background-color: #F0F3F8;
    border-color: black;
    transition: background-color 300ms linear, border-color 300ms linear;

    img{
        background-color: white;
        transition: background-color 300ms linear;
    }
}

.LoadingContainer {

}

.LoadingContainer p {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 3rem;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px){
    /*Mobile*/

    .Container {
        max-height: fit-content;
        overflow: auto;
        padding-bottom: 1rem;
    }


    .RecentEventTitle {
        padding: 0 0 1rem 0;
        text-align: center;
        font-size: 1.8rem;
        margin: 3vh 0 0 0;
    }

    .Title {
        font-size: 1.3rem;
        line-height: 1;


    }

    .TitleDiv {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        padding-top: 1rem;
        margin-bottom: 2rem;
    }

    .Line {
        width: 60%;
        height: 2px;
        background: #F0F3F8;
    }

    .LoadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: transparent;

    }

    .LoadingContainer p {
        font-size: 1rem;
        font-weight: 400;
        margin: 3rem;
    }

    .AddEventButton {

    }



}