.Container {
    display: flex;
    height: 100vh;

}
.LeftSide {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RightSide {
    width: 50%;
}
.RightSide img {
    width: 100%;
    height: auto;
}

.FormContainer {
    min-width: 60%;
}
.Header {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 0; /* 0px */
    letter-spacing: 3px;
}
.Subtitle {
    padding-bottom: 1.5rem;

}
.Label {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 0.9;
}
.Input {
    width: 18vw;
    height: 2.8rem;
    border-radius: 10px;
    border: 2px solid #DAE0EC;
    margin-bottom: 2rem;
    padding-left: 1rem;
}

.LoginButton {
    width: 18vw;
    color: #FFF;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 1px;
    border-radius: 10px;
    background: #000;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    transition: background-color 200ms linear, color 200ms linear, border-color 200ms linear;

}

.LoginButton:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
    transition: background-color 200ms linear, color 200ms linear, border-color 200ms linear;
}

.HomePageButton {
    text-decoration: none;
    color: black;
    height: 1rem;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.5s linear;
}
.HomePageButton:hover {
    border-bottom: 2px solid black;
    transition: border-bottom-color 0.5s linear;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    /*Tablet*/

}


@media screen and (max-width: 767px) {
    /*Mobile*/
    .RightSide {
        display: none;
    }
    .LeftSide {
        width: 100%;
    }
    .FormContainer {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;

    }
    .Header {
        text-align: center;
    }
    .Subtitle {
        text-align: center;
    }
    .Label {
        width: 80%;

    }

    .Input {
        width: 80%;
        height: 2.8rem;
        border-radius: 10px;
        border: 2px solid #DAE0EC;
        margin: 0 auto 2rem;
        padding-left: 1rem;
    }

    .LoginButton {
        width: 100%;
        color: #FFF;
        padding: 0.5rem 0;
        margin-bottom: 1rem;

    }
    .ButtonDiv{
        width: 80%;
    }


}